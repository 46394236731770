

.fullPage {
      display: block;
      padding: 50px;
}

br {
      display: block;
      height: 0;
}

.header {
      display: flex;
      justify-content: center;
}

.headerContents {
      display: flex;
      flex-direction: column;
      padding: 1rem 0;
}

.value-viewer {
      /* margin: 10px; */
      /* margin-left: 10px; */
      padding: 1px 10px;
}
